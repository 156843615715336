import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createRouterM from './router/'
import createStore from './store'
// 把 Vue Router 当前的 $route 同步为 Vuex 状态的一部分
import { sync } from 'vuex-router-sync'
import global from '@/utlis/global'
import UAParser from 'ua-parser-js'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
const firebaseConfig = {
	apiKey: 'AIzaSyBi2TGZX-T3tcT2nTyq366jKD69gx5lZZk',
	authDomain: 'whatsmodapk-net.firebaseapp.com',
	projectId: 'whatsmodapk-net',
	storageBucket: 'whatsmodapk-net.appspot.com',
	messagingSenderId: '246689964175',
	appId: '1:246689964175:web:9112bc15a5cdd56f3210fa',
	measurementId: 'G-TL3PBV7XBZ',
}
// Initialize Firebase
let app = null
let analytics = null

const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'

isSupported().then((result) => {
	if (result) {
		app = initializeApp(firebaseConfig)
		analytics = getAnalytics(app)

		logEvent(analytics, 'in_page_gb')
	}
})

// simple event

Vue.prototype.$global = global
Vue.prototype.$server = global.server
Vue.prototype.$ua = {
	deviceInfo,
	isMobileDevice,
}
Vue.prototype.$logEvent = (event, params = {}) => {
	isSupported().then((result) => {
		if (result) {
			logEvent(analytics, event, params)
		}
	})
}

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	// 同步路由状态(route state)到 store
	sync(store, router)
	router.afterEach((to, from, next) => {
		if (typeof document !== 'undefined') document.title = to.meta.title || 'Gallery Board for Whatsapp - GBWhatsapp'
	})
	const app = new Vue({
		router,
		store,
		data() {
			return {
				baseInfo: null,
				isMobileDevice: isMobileDevice,
			}
		},
		created() {
			var date = new Date()
			var monthArray = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December')
			var month = date.getMonth()
			this.$store.commit('UPDATE_MONTH', monthArray[month])
		},
		mounted() {
			this.baseInfo = window.initData ? JSON.parse(initData) : { settings: { score: '3.54', appId: 'gbw', updateTime: 1670987497036, totalCount: 4567890 }, partners: [] }
			const body = document.querySelector('body')
			if (this._isMobile()) {
				body.classList.add('is_mobile','i_m')
			}
			if (!this._isMobile()) {
				body.classList.add('is_pc','i_pc')
			}

			// this.getbaseInfo()
		},
		methods: {
			_isMobile() {
				if (typeof navigator !== 'undefined') {
					// 在浏览器环境中执行的代码
					// 使用 navigator 对象进行相关操作
					return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
				} else {
					// 在服务器环境中执行的代码
					// 忽略或提供替代方案
					return false
				}
			},
			getbaseInfo() {
				this.$server
					.init({
						official: { promotionChannel: 'gb', promotionName: 'gb' },
						apk: { domain: 'gbwhatsapp.download', appName: 'GB' },
						as: 'gbw',
					})
					.then((res) => {
						if (res.code == 200) {
							this.baseInfo = res.data.settings
						} else {
							this.baseInfo = {
								score: '3.54',
								appId: 'gbw',
								updateTime: 1690547408596,
								totalCount: 4567890,
							}
						}
					})
					.catch((error) => {
						console.error('Error:', error)
						this.baseInfo = {
							score: '3.54',
							appId: 'gbw',
							updateTime: 1690547408596,
							totalCount: 5839929,
						}
					})
			},
		},
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
