<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>


<script>
import ClientOnly from "vue-client-only";
import "@/css/main.scss";

export default {
	name: "app",
	components: {
		ClientOnly,
	},
	data() {
		return {
			myApp: null,
			screenWidth: null,
			is_mobile: null,
		};
	},
	computed: {
		deviceType() {
			// 在服务器端渲染期间，从Vue SSR上下文中获取设备类型
			if (this.$ssrContext) {
				return this.$ssrContext.deviceType;
			}

			// 在客户端渲染期间，使用窗口宽度检测设备类型
			const windowWidth =
				window.innerWidth || document.documentElement.clientWidth;
			return windowWidth < 768 ? "mobile" : "desktop";
		},
		isMobile() {
			return this.deviceType === "mobile";
		},
	},
	mounted() {
		this.myApp = this.$store.state.gb;
		this.$logEvent("show_gbhome");

		setTimeout(() => {
			this.showTeleMsg = false;
		}, 2000);
	},

};
</script>
