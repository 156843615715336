import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			count: 1000,
			activeAPK: null,
			activeName: null,
			activeLogo: null,
			lastPage: null,
			voteScore: null,
			avtiveNav: 'gbpro',
			month: 'December',
		},
		modules,
		mutations: {
			UPDATE_VOTING(state, num) {
				state.voteScore = num
			},
			UPDATE_NAV(state, nav) {
				state.avtiveNav = nav
			},
			UPDATE_MONTH(state, month) {
				state.month = month
			},
		},
	})
}
