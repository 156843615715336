import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
// import Home from '../views/gb/pc.vue'
import UAParser from 'ua-parser-js'
const uaParser = new UAParser()
const deviceInfo = uaParser.getResult()

const isMobileDevice = deviceInfo.device.type === 'mobile'
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		component: () => import('@/pages/pc.vue'),
		name: 'home_pc',
		meta: {
			title: 'Gallery Board for Whatsapp - GBWhatsapp',
		},
	},
	{ path: '*', redirect: '/' },
]

export const constRoutesM = [
	{
		path: '/',
		component: () => import('@/pages/mobile.vue'),
		name: 'home_m',
		meta: {
			title: 'Gallery Board for Whatsapp - GBWhatsapp',
		},
	},

	{ path: '*', redirect: '/' },
]

var routes = []
if (isMobileDevice) {
	routes = constRoutesM
}
if (!isMobileDevice) {
	routes = constRoutesPc
}

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
